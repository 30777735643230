import * as React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { firebaseAuth } from "../config";
import { AuthContext } from "../context";

const getShopById = async (id: string): Promise<any> => {
  const apiQueryString = `${process.env.REACT_APP_RESPO_API_URL}/shops/${id}`;
  const user = firebaseAuth.currentUser;
  if (user) {
    const token = await user.getIdToken(true);
    const { data } = await axios.get(apiQueryString, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return data;
  }
};

const useGetShopById = (id: string): any => {
  const { user } = React.useContext(AuthContext);
  const isEnabled =
    user.uid !== "" && id.length > 0 && id !== "signup" && id !== "signin" && id !== "confirm-otp";
  return useQuery(["shopById", id], () => getShopById(id), {
    retry: 1,
    enabled: isEnabled
  });
};

export { useGetShopById };
