import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "../context";

const PrivateRoute = ({ children, ...rest }: any) => {
  const { user } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.uid ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/auth/signin?intent=access&path=${location.pathname}&ep=${
                queryString.parse(location.search).ep
              }`
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
