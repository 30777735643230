import * as React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./styles.sass";

const LoadingLogoPage: React.FC = () => {
  return (
    <div className="loading-logo">
      <img src={`${process.env.PUBLIC_URL}/assets/respo_logo.png`} alt="respo logo" />
      <PuffLoader color="#00B7CE" />
    </div>
  );
};

export default LoadingLogoPage;
