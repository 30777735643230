import * as React from "react";
// @ts-ignore
import loadable from "@loadable/component";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AuthContext } from "../context";
import PrivateRoute from "./PrivateRoute";
import LoadingLogoPage from "../pages/LoadingLogoPage";
import InterceptLineAuthInRoute from "./InterceptLineAuthInRoute";
import Helmet from "./Helmet";
import { PageTitles } from "../utils";

const SignIn = loadable(() => import("../pages/SignInPage"));
const AuthOTPPage = loadable(() => import("../pages/AuthOTPPage"));
const ShopHome = loadable(() => import("../pages/ShopHomePage"));
const NotFound = loadable(() => import("../pages/NotFoundPage"));
const TipPage = loadable(() => import("../pages/TipPage"));
const TipSuccess = loadable(() => import("../pages/TipSuccessPage"));
const CreditCardCheckoutPage = loadable(() => import("../pages/CreditCardCheckoutPage"));
const AmazonCheckoutReviewPage = loadable(() => import("../pages/AmazonCheckoutReviewPage"));

const App = (): JSX.Element => {
  const { pathname } = useLocation();
  const { globalLoading, is404 } = React.useContext(AuthContext);

  // const redirectDesktopUsers = (): void => {
  //   !isMobile && window.location.replace("https://respo.info");
  // };
  //
  // React.useEffect(() => {
  //   redirectDesktopUsers();
  // }, []);

  return (
    <div className="temp-App  body-wrapper" style={{ backgroundColor: "#f5f5f5" }}>
      <Helmet title={PageTitles.LOADING} />
      {!globalLoading ? (
        !is404 ? (
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <PrivateRoute path="/shops/:shopId/:employeeId/tip-review">
              <AmazonCheckoutReviewPage />
            </PrivateRoute>
            <PrivateRoute path="/shops/:shopId/:employeeId/tip-success">
              <TipSuccess />
            </PrivateRoute>
            <PrivateRoute path="/shops/:shopId/:employeeId/checkout">
              <CreditCardCheckoutPage />
            </PrivateRoute>
            <PrivateRoute path="/shops/:shopId/:employeeId/tip">
              <TipPage />
            </PrivateRoute>
            <PrivateRoute path="/shops/:shopId">
              <ShopHome />
            </PrivateRoute>
            <Route path="/auth/confirm-otp" component={AuthOTPPage} />
            <InterceptLineAuthInRoute>
              <Route path="/auth/signin*" component={SignIn} />
            </InterceptLineAuthInRoute>
            {/* <Route */}
            {/*  exact */}
            {/*  path="/" */}
            {/*  component={() => { */}
            {/*    window.location.replace("https://respo.info"); */}
            {/*    return null; */}
            {/*  }} */}
            {/* /> */}
            <Route component={NotFound} />
          </Switch>
        ) : (
          <NotFound />
        )
      ) : (
        <LoadingLogoPage />
      )}
    </div>
  );
};

export default App;
