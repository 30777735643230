import * as React from "react";
import { Helmet as ReactHelmet } from "react-helmet";

export interface HelmetProps {
  title: string;
}

const Helmet: React.FC<HelmetProps> = ({ title }) => {
  return (
    <ReactHelmet>
      <title>{title}</title>
    </ReactHelmet>
  );
};

export default Helmet;
