import React from "react";
import ReactDOM from "react-dom";
import { StylesProvider, ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { QueryParamProvider } from "use-query-params";
import { ReactQueryDevtools } from "react-query/devtools";
import App from "./components/App";
import { AuthProvider, ShopProvider } from "./context";
import "./config/global.sass";

// Initialize react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#00B7CE"
    }
  },
  typography: {
    fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif"`
  }
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <ShopProvider>
                <App />
                <ReactQueryDevtools />
              </ShopProvider>
            </AuthProvider>
          </ThemeProvider>
        </StylesProvider>
      </QueryParamProvider>
    </Router>
  </QueryClientProvider>,
  document.getElementById("root")
);
