import * as React from "react";

export interface Alert {
  isOpen: boolean;
  message: string;
}

export interface AlertHook {
  alert: Alert;
  handleAlertOpen: (error: string) => void;
  handleAlertClose: (event?: any, reason?: string) => any;
}

const useAlert = (): AlertHook => {
  const [alert, setAlert] = React.useState<Alert>({
    isOpen: false,
    message: ""
  });

  const handleAlertOpen = (errorString: string) => {
    setAlert(() => ({
      isOpen: true,
      message: errorString
    }));
  };

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    setAlert(({ message }) => ({
      isOpen: false,
      message
    }));
  };

  return { alert, handleAlertOpen, handleAlertClose };
};

export default useAlert;
