import * as React from "react";
import { Backdrop } from "@mui/material";
import PuffLoader from "react-spinners/PuffLoader";

const BackdropLoader = ({ isLoading }: { isLoading: boolean }): JSX.Element => {
  return (
    <Backdrop open={isLoading} sx={{ zIndex: 9999 }}>
      <PuffLoader color="#00B7CE" />
    </Backdrop>
  );
};

export default BackdropLoader;
