import * as React from "react";
import { Route, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import queryString from "query-string";
import { Snackbar } from "@mui/material";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { firebaseAuth } from "../config";
import {
  createUserWithPhoneNumber,
  issueLineAccessToken,
  signInOrSignUpUserWithLineAccessToken,
  useAlert
} from "../hooks";
import { AuthContext, ShopContext } from "../context";
import BackdropLoader from "./BackdropLoader";
import Alert from "./Alert";
import { lineAuthSafe } from "../utils";

const InterceptLineAuthInRoute = ({ children, ...rest }: any) => {
  const { setUser } = React.useContext(AuthContext);
  const { currentShopId, currentEmployeeId, setCurrentShopId, setCurrentEmployeeId } =
    React.useContext(ShopContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const { handleAlertOpen, handleAlertClose, alert } = useAlert();

  const [query] = useQueryParams({
    code: withDefault(StringParam, ""),
    state: withDefault(StringParam, ""),
    error: withDefault(StringParam, "")
  });

  const { code, state, error } = query;

  const useCreateUserWithPhoneNumber = useMutation(createUserWithPhoneNumber, {
    onSuccess: async () => {
      setIsLoading(false);
      const intent = window.localStorage.getItem("intent");

      if (!intent) {
        history.replace("/");
      } else {
        history.push(intent);
        const splitPath = intent.split(/\?|\//);
        splitPath.length >= 3 && !currentShopId && setCurrentShopId(splitPath[2]);
        splitPath.length >= 4 && !currentEmployeeId && setCurrentEmployeeId(splitPath[3]);
      }
    },
    onError: (err) => {
      setIsLoading(false);
      console.log(err);
    }
  });

  const useSignInOrSignUpUserWithLineAccessToken = useMutation(
    signInOrSignUpUserWithLineAccessToken,
    {
      onSuccess: async (data) => {
        const token = data?.token;

        if (token) {
          const user = await firebaseAuth.signInWithCustomToken(token);
          setUser(user);

          const parsedQueryString = queryString.parse(window.localStorage.getItem("intent") || "");
          const entrypoint = `${parsedQueryString?.ep}`;
          const path = `${parsedQueryString?.path}`;
          const splitPath = path.split(/\?|\//);
          const shopId = splitPath[2] ? splitPath[2] : "null";
          const employeeId = splitPath[3] ? splitPath[3] : "null";

          useCreateUserWithPhoneNumber.mutate({
            entrypoint,
            shopId,
            employeeId
          });
        } else {
          console.log("token does not exist!");
        }
      },
      onError: (err) => {
        console.log(err);
      }
    }
  );

  const useIssueLineAccessToken = useMutation(issueLineAccessToken, {
    onSuccess: (data) => {
      if (!!data && data?.access_token !== undefined) {
        const modifiedState = lineAuthSafe.decode(state);
        window.localStorage.setItem("intent", modifiedState);
        useSignInOrSignUpUserWithLineAccessToken.mutate(data);
      } else if (data === undefined) {
        // handle Line auth error
        setIsLoading(false);
        handleAlertOpen("Line認証に失敗しました。もう一度試してください。");
      }
    },
    onError: (err) => {
      console.log(err);
    }
  });

  React.useEffect(() => {
    if (code !== undefined && !!code) {
      setIsLoading(true);
      useIssueLineAccessToken.mutate({ code, state });
    } else if (error !== undefined && !!error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={() => (
        <>
          {children}
          <BackdropLoader isLoading={isLoading} />
          <Snackbar
            open={alert.isOpen}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2500}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity="error">
              {alert.message}
            </Alert>
          </Snackbar>
        </>
      )}
    />
  );
};

export default InterceptLineAuthInRoute;
