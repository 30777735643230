import axios, { AxiosError } from "axios";
import { firebaseAuth } from "../config";
import { EntrypointProps } from "../types";

const createUserWithPhoneNumber = async ({
  entrypoint,
  shopId,
  employeeId
}: EntrypointProps): Promise<any> => {
  const apiQueryString = `${process.env.REACT_APP_RESPO_API_URL}/users`;
  try {
    const user = firebaseAuth.currentUser;

    if (user) {
      const token = await user.getIdToken(true);
      const { data } = await axios.post(
        apiQueryString,
        {
          entrypoint,
          shopId,
          employeeId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};

interface AxiosResponseError {
  error: string;
}

interface SendSMSOTPResponseData {
  token: string;
  message: string;
}

interface VerifySMSOTPRequestBody {
  otp: string;
  token: string;
}

// TODO: resolve issue where resending the SMS OTP will result in a 400 error
//   storing the token in sessionStorage?
const sendSMSOTP = async (phoneNumber: string): Promise<void> => {
  const query = `${process.env.REACT_APP_RESPO_API_URL}/auth/send-otp`;
  const response = await axios
    .post<SendSMSOTPResponseData>(query, { phoneNumber })
    .catch((err: AxiosError<AxiosResponseError>) => console.log(err));

  if (response?.data !== undefined && !!response?.data) {
    const { token } = response?.data;
    window.sessionStorage.setItem("phoneNumberToken", token);
  } else {
    // if response is not successfully received
    console.log("token not received!");
  }
};

interface AuthResponse {
  token: string;
  message: string;
}

const verifySMSOTP = async (otp: string): Promise<any> => {
  const query = `${process.env.REACT_APP_RESPO_API_URL}/auth/verify-otp?clientType=c`;
  const token = window.sessionStorage.getItem("phoneNumberToken") || "";
  const requestBody: VerifySMSOTPRequestBody = { otp, token };
  const response = await axios
    .post(query, requestBody)
    .catch((err: AxiosError<AxiosResponseError>) => console.log(err));
  if (response?.data !== undefined && !!response?.data) {
    return response.data;
  }
  console.log("verifySMSOTP error");
};

const issueLineAccessToken = async ({
  code,
  state
}: {
  code: string;
  state: string;
}): Promise<LineAccessToken | void> => {
  const query = `https://api.line.me/oauth2/v2.1/token`;
  const requestBodyObject = {
    grant_type: "authorization_code",
    code,
    redirect_uri: process.env.REACT_APP_LINE_REDIRECT_URI as string,
    client_id: process.env.REACT_APP_LINE_CLIENT_ID as string,
    client_secret: process.env.REACT_APP_LINE_CHANNEL_SECRET as string
  };

  const response = await axios
    .post<LineAccessToken>(query, new URLSearchParams(requestBodyObject), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .catch((err: AxiosError<AxiosResponseError>) => console.log(err));

  if (response?.data !== undefined && !!response?.data) {
    return response.data;
  }
  console.log("getting line auth access token failed");
};

interface LineAccessToken {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

const signInOrSignUpUserWithLineAccessToken = async (
  data: LineAccessToken
): Promise<AuthResponse | void> => {
  const query = `${process.env.REACT_APP_RESPO_API_URL}/auth/line`;
  const response = await axios
    .post<AuthResponse>(query, data)
    .catch((err: AxiosError<AxiosResponseError>) => console.log(err));

  if (response?.data !== undefined && !!response?.data) {
    return response.data;
  }
  console.log("getting firebase token w/ line access_token failed");
};

export {
  createUserWithPhoneNumber,
  sendSMSOTP,
  verifySMSOTP,
  issueLineAccessToken,
  signInOrSignUpUserWithLineAccessToken
};
