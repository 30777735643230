import * as React from "react";
import { useLocation } from "react-router-dom";
import { useGetShopById } from "../hooks";
import { AuthContext } from "./AuthContext";

interface ShopContextInterface {
  currentShopId: any;
  setCurrentShopId: any;
  currentEmployeeId: any;
  setCurrentEmployeeId: any;
  status: any;
  data: any;
}

const shopContextDefaultValue: ShopContextInterface = {
  currentShopId: "",
  setCurrentShopId: () => {
    // this is intentional for initializing context
  },
  currentEmployeeId: "",
  setCurrentEmployeeId: () => {
    // this is intentional for initializing context
  },
  status: "",
  data: {}
};

const ShopContext = React.createContext<ShopContextInterface>(shopContextDefaultValue);

const ShopProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [currentShopId, setCurrentShopId] = React.useState("");
  const [currentEmployeeId, setCurrentEmployeeId] = React.useState("");
  const { setIs404 } = React.useContext(AuthContext);
  const { status, data } = useGetShopById(currentShopId);

  React.useEffect(() => {
    if (data?.error || status === "error") {
      setIs404(true);
    }
  });

  // update current shopId if pathname changes && the new shopId differs from the current one
  React.useEffect(() => {
    const splitPathname = location.pathname.split("/");
    if (splitPathname.length >= 3) {
      const newShopId = splitPathname[2];
      const isValidAndDiff =
        newShopId !== currentShopId && newShopId !== "signin" && newShopId !== "auth";
      isValidAndDiff && setCurrentShopId(newShopId);
    }
    if (splitPathname.length > 3) {
      const newEmployeeId = splitPathname[3];
      currentShopId !== "signin" &&
        currentEmployeeId !== newEmployeeId &&
        setCurrentEmployeeId(newEmployeeId);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <ShopContext.Provider
      value={{
        currentShopId,
        setCurrentShopId,
        currentEmployeeId,
        setCurrentEmployeeId,
        status,
        data
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export { ShopContext, ShopProvider };
