import * as React from "react";
import { useLocation } from "react-router-dom";
import { firebaseAuth } from "../config";

interface AuthContextInterface {
  user: any;

  setUser(newUser: object): void;

  globalLoading: boolean;

  setGlobalLoading(newGlobalLoadingState: boolean): void;

  is404: boolean;

  setIs404(newIs404State: boolean): void;
}

const authContextDefaultValue: AuthContextInterface = {
  user: {},
  setUser: () => {
    // this is intentional for initializing context
  },
  globalLoading: true,
  setGlobalLoading: () => {
    // this is intentional for initializing context
  },
  is404: false,
  setIs404: () => {
    // this is intentional for initializing context
  }
};

const AuthContext = React.createContext<AuthContextInterface>(authContextDefaultValue);

const AuthProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const [globalLoading, setGlobalLoading] = React.useState(true);
  const [is404, setIs404] = React.useState(false);
  const [user, setUser] = React.useState({ uid: "" });

  React.useEffect(() => {
    const splitPathname = pathname.split("/");
    // if (splitPathname[1] !== 'auth' && splitPathname[1] !== 'shops' ) {
    if (splitPathname[1] !== "auth" && splitPathname[1] !== "shops") {
      setIs404(true);
    } else {
      setIs404(false);
    }
  }, [pathname]);

  React.useEffect(() => {
    firebaseAuth.onAuthStateChanged((result) => {
      if (result) {
        if (result.uid !== user.uid) {
          setUser(result);
          setGlobalLoading(false);
        } else {
          setGlobalLoading(false);
        }
      } else {
        setGlobalLoading(false);
      }
    });
  });

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        globalLoading,
        setGlobalLoading,
        is404,
        setIs404
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
