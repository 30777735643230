const PageTitle = {
  HOME: "店舗ホーム",
  SIGNIN: "ログイン",
  CONFIRM_OTP: "ワンタイムパスワード確定",
  NOT_FOUND: "ページが見つかりませんでした。",
  LOADING: "ロード中...",
  TIP_SUCCESS: "応援完了",
  CHECKOUT: "応援を確定する",
  TIP: "応援する",
  STAFF_MODAL: "スタッフを確定する",
  CONFIRM_AMAZONPAY: "AmazonPayで応援する"
};

export default PageTitle;
