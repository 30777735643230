export const handleImgSrcError = (e: any) => {
  const { target } = e;
  target.onerror = null;
  target.src = `${process.env.PUBLIC_URL}/assets/default_avatar.jpeg`;
};

export const formatPhoneNumber = (userInput: string): string => {
  const validatedString = `+81${userInput
    .replace(/\(|\)|\s|-/gi, "")
    .split("")
    .slice(1)
    .join("")}`;
  return validatedString;
};

export const createBearer = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

export const lineAuthSafe = {
  encode(raw: string) {
    return raw.replace(/\?/, "__q__");
  },
  decode(encodedRaw: string) {
    return encodedRaw.replace(/__q__/, "?");
  }
};

export type LogEvent = {
  eventName: string;
  screenName: string;
  value: any;
};

export const isInternalShopId = (shopId: string) => {
  const INTERNAL_SHOP_IDS = process.env.REACT_APP_INTERNAL_SHOP_IDS || "";
  const internalShopIds = INTERNAL_SHOP_IDS.split(",").map((internalShopId) =>
    internalShopId.trim()
  );
  return internalShopIds.includes(shopId);
};

export const normalize = (val: string) => {
  return val.slice(1, -1);
};
