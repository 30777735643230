import axios from "axios";

import { useQuery } from "react-query";
import { firebaseAuth } from "../config";
import { createBearer } from "../utils";

export interface CreateStripeCheckoutSessionProps {
  amount: number;
  employeeId: string;
  shopId: string;
  details: object;
}

const createStripeCheckoutSession = async ({
  amount,
  employeeId,
  shopId,
  details
}: CreateStripeCheckoutSessionProps) => {
  const user = firebaseAuth.currentUser;
  if (user) {
    const query = `${process.env.REACT_APP_RESPO_API_URL}/payments/stripe/sessions`;
    const token = await user.getIdToken(true);
    const { data } = await axios.post(
      query,
      {
        userId: user.uid,
        shopId,
        amount,
        employeeId,
        details
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return data;
  }
};

const getStripeSessionInfo = async ({
  sessionId,
  transactionId
}: {
  sessionId: string;
  transactionId: string;
}): Promise<any> => {
  const apiQueryString = `${process.env.REACT_APP_RESPO_API_URL}/payments/stripe/sessions/${sessionId}?transactionId=${transactionId}`;
  try {
    const user = firebaseAuth.currentUser;
    if (user) {
      const token = await user?.getIdToken(true);
      const { data } = await axios.get(apiQueryString, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return data;
    }
    return false;
  } catch (error) {
    console.log(error);
  }
};

const useGetStripeSessionInfo = (sessionId: string, transactionId: string) => {
  const isEnabled = !!transactionId.length && !!sessionId.length;
  return useQuery(
    ["stripeSessionInfo", sessionId, transactionId],
    () => getStripeSessionInfo({ sessionId, transactionId }),
    {
      enabled: isEnabled
    }
  );
};

export interface CreateApPoolItemProps {
  amount: number;
  employeeId: string;
  details: object;
  profilePictureUrl: string;
}

const createApPoolItem = async ({
  amount,
  employeeId,
  details,
  profilePictureUrl
}: CreateApPoolItemProps) => {
  const user = firebaseAuth.currentUser;
  if (user) {
    const query = `${process.env.REACT_APP_RESPO_API_URL}/payments/amazon/pool`;
    const token = await user.getIdToken(true);
    const { data } = await axios.post(
      query,
      { amount, employeeId, details, profilePictureUrl },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return { data };
  }
};

export interface CheckoutSessionProps {
  sessionId: string;
  amount: string;
}

export interface UpdateApCheckoutSessionProps extends CheckoutSessionProps {
  employeeId: string;
  shopId: string;
  details: object;
}

const updateApCheckoutSession = async ({
  sessionId,
  employeeId,
  shopId,
  amount,
  details
}: UpdateApCheckoutSessionProps) => {
  try {
    const user = firebaseAuth.currentUser;
    if (user) {
      const query = `${process.env.REACT_APP_RESPO_API_URL}/payments/amazon`;
      const token = await user.getIdToken(true);
      const { data } = await axios.put(
        query,
        { sessionId, employeeId, shopId, amount, details },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const completeAPCheckoutSession = async ({ sessionId, amount }: CheckoutSessionProps) => {
  try {
    const user = firebaseAuth.currentUser;
    if (user) {
      const query = `${process.env.REACT_APP_RESPO_API_URL}/payments/amazon/${sessionId}/complete`;
      const token = await user.getIdToken(true);
      const { data } = await axios.post(
        query,
        { amount },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const confirmFreeTip = async ({
  transactionId,
  sessionId
}: {
  transactionId: string;
  sessionId: string;
}) => {
  const user = firebaseAuth.currentUser;
  if (user) {
    const query = `${process.env.REACT_APP_RESPO_API_URL}/payments/${transactionId}/confirm`;
    const token = await user.getIdToken(true);
    const { data } = await axios.put(query, { sessionId }, createBearer(token));
    return data;
  }
  throw Error("User not logged in.");
};

export {
  createApPoolItem,
  getStripeSessionInfo,
  useGetStripeSessionInfo,
  updateApCheckoutSession,
  completeAPCheckoutSession,
  createStripeCheckoutSession,
  confirmFreeTip
};
