export const FIELD_REQUIRED = "入力してください";

// messages for phone number input
export const INVALID_PHONE_NUMBER_MESSAGE = "有効な電話番号を入力してください";

// messages for otp confirmation
export const INVALID_OTP_MESSAGE = "有効な認証コードを入力してください";

//
export const UNDER_MIN_AMOUNT_MESSAGE = "100pt以上の数字を入力してください";
export const OVER_MAX_AMOUNT_MESSAGE = "999,999pt以下の間の値を指定してください";

export const INVALID_NICKNAME_MESSAGE = "有効なニックネームを入力してください";
export const INVALID_COMMENT_MESSAGE = "有効なコメントを入力してください";

export const ErrorMessages = {
  ShopNotFound: "検索された店舗が存在しません。",
  StaffToTipNotFound:
    "チップしたいスタッフが見つかりませんでした。違うスタッフを選択してください。",
  InternalServerError: "サーバー内エラーが発生しました。",
  RiskyUserDetected: "アクセス権限がありません。"
};

export const HOBBY_LIST = [
  { label: "🤣お笑い", value: "comedy" },
  { label: "🎤カラオケ", value: "karaoke" },
  { label: "🍺ビール", value: "beer" },
  { label: "🍶日本酒", value: "sake" },
  { label: "🍷ワイン", value: "wine" },
  { label: "🥃ウィスキー", value: "whiskey" },
  { label: "🍾シャンパン", value: "champagne" },
  { label: "🍻お酒", value: "alcohol" },
  { label: "👾ゲーム", value: "games" },
  { label: "🏔アウトドア", value: "outdoor" },
  { label: "🏠インドア", value: "indoor" },
  { label: "⚾️野球", value: "baseball" },
  { label: "⚽️サッカー", value: "soccer" },
  { label: "⛳️ゴルフ", value: "golf" },
  { label: "🏀バスケ", value: "basketball" },
  { label: "🎾テニス", value: "tennis" },
  { label: "🚗車", value: "cars" },
  { label: "📚読書", value: "books" },
  { label: "✈️旅行", value: "travel" },
  { label: "🎬映画", value: "movies" },
  { label: "😽猫派", value: "cat" },
  { label: "🐶犬派", value: "dog" },
  { label: "📸カメラ", value: "camera" },
  { label: "🀄️麻雀", value: "mahjong" },
  { label: "♦️ポーカー", value: "poker" },
  { label: "🎲ボードゲーム", value: "boardgames" },
  { label: "🔤多言語", value: "multilingual" },
  { label: "💉済", value: "vaccinated" },
  { label: "🥷アニメ", value: "anime" },
  { label: "📖漫画", value: "manga" },
  { label: "🍣寿司", value: "sushi" },
  { label: "🍖肉", value: "meat" },
  { label: "🐟海鮮", value: "seafood" },
  { label: "🍜ラーメン", value: "ramen" },
  { label: "☕️カフェ", value: "cafe" },
  { label: "🛍ショッピング", value: "shopping" },
  { label: "👚ファッション", value: "fashion" },
  { label: "🎸バンド", value: "band" },
  { label: "🎹クラシック", value: "classical" },
  { label: "🎷ジャズ", value: "jazz" }
];

export const SliderConstants = {
  MARKS: [
    {
      value: 0,
      label: ""
    },
    {
      value: 1,
      label: "500"
    },
    {
      value: 2,
      label: ""
    },
    {
      value: 3,
      label: ""
    },
    {
      value: 4,
      label: ""
    },
    {
      value: 5,
      label: ""
    },
    {
      value: 6,
      label: ""
    },
    {
      value: 7,
      label: ""
    },
    {
      value: 8,
      label: ""
    },
    {
      value: 9,
      label: ""
    },
    {
      value: 10,
      label: ""
    },
    {
      value: 11,
      label: "50,000"
    }
  ],
  SCALE: [0, 500, 700, 1000, 1500, 2000, 5000, 10000, 20000, 30000, 40000, 50000]
};

export const COLORS = [
  { color: "#40B0E0", borderColor: "#12A0DD" },
  { color: "#C0D734", borderColor: "#AAC600" },
  { color: "#E58A57", borderColor: "#E26F2E" },
  { color: "#E2679B", borderColor: "#DC4887" }
];

export const FeedbackTypes = {
  SMILE: "smile",
  ENERGETIC: "energetic",
  CARE: "care",
  CONVERSATION: "conversation",
  ATMOSPHERE: "atmosphere",
  CLEAN: "clean"
};

export const FEEDBACK_SELECTION = [
  {
    feedbackId: FeedbackTypes.SMILE,
    label: "また会いたくなるような素敵な笑顔",
    imgSrc: `${process.env.PUBLIC_URL}/assets//eval_smile.svg`
  },
  {
    feedbackId: FeedbackTypes.ENERGETIC,
    label: "太陽のような元気な接客",
    imgSrc: `${process.env.PUBLIC_URL}/assets/eval_sun.svg`
  },
  {
    feedbackId: FeedbackTypes.CARE,
    label: "サメのような些細な気遣い",
    imgSrc: `${process.env.PUBLIC_URL}/assets/eval_shark.svg`
  },
  {
    feedbackId: FeedbackTypes.CONVERSATION,
    label: "聖徳太子のような話しやすい",
    imgSrc: `${process.env.PUBLIC_URL}/assets/eval_talk.svg`
  },
  {
    feedbackId: FeedbackTypes.ATMOSPHERE,
    label: "洗練された雰囲気",
    imgSrc: `${process.env.PUBLIC_URL}/assets/eval_atmosphere.svg`
  },
  {
    feedbackId: FeedbackTypes.CLEAN,
    label: "徹底された感染症対策",
    imgSrc: `${process.env.PUBLIC_URL}/assets/eval_clean.svg`
  }
];

export const ScreenNames = {
  HOME: "home",
  TIP: "tip",
  TIP_SUCCESS: "tip_success",
  NOT_FOUND: "not_found",
  AMAZON_PAY_REVIEW: "amazon_pay_review",
  AUTH_OTP: "otp",
  AUTH_PHONE_NUMBER: "phone_number",
  CREDIT_CARD_CHECKOUT: "credit_card_checkout"
};

export const DEFAULT_COMMENT_COLOR = "#40B0E0";

export const StripeErrors = {
  INCORRECT_CVC: "incorrect_cvc",
  GENERIC_CARD_DECLINED: "generic_declined",
  CARD_DECLINED: "card_declined",
  INSUFFICIENT_FUNDS: "insufficient_funds",
  LOST_CARD: "lost_card",
  STOLEN_CARD: "stolen_card",
  EXPIRED_CARD: "expired_card",
  INCORRECT_NUMBER: "incorrect_number",
  PAYMENT_INTENT_UNEXPECTED_STATE: "payment_intent_unexpected_state",
  PROCESSING_ERROR: "processing_error"
};

export const StripeErrorMessages = {
  INCORRECT_CVC: "CVCが正しくありません。",
  CARD_DECLINED: "登録されたクレジットカードが使用できませんでした。",
  INSUFFICIENT_FUNDS: "クレジットカードの残高が不足しております。",
  LOST_CARD: "登録されたクレジットカードの紛失届が提出されているため、現在利用できません。",
  STOLEN_CARD: "登録されたクレジットカードの盗難届が提出されているため、現在利用できません。",
  EXPIRED_CARD: "登録されたクレジットカードの有効期限が切れているため、利用できません。",
  INCORRECT_NUMBER: "登録されたクレジットカード番号が有効ではありません。",
  PAYMENT_INTENT_UNEXPECTED_STATE: "既に決済が終了しております。スタッフ一覧画面に戻ります。",
  PROCESSING_ERROR: "決済処理ができませんでした。"
};
