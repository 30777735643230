export const IMG_TYPES = {
  STAFF_PROFILE: "staff_profile",
  SHOP_HERO: "shop_hero"
};

const toImgixUrl = (url: string, query: string, type?: string) => {
  if (type === undefined) {
    type = IMG_TYPES.STAFF_PROFILE;
  }
  const splitUrl = url.split(/amazonaws.com|imgix.net|\?/);
  const imgPath = splitUrl[1];
  if (type === IMG_TYPES.STAFF_PROFILE) {
    const imgixUrl = `${process.env.REACT_APP_IMGIX_URL}${imgPath}${query}`;
    return imgixUrl;
  }
  if (type === IMG_TYPES.SHOP_HERO) {
    const imgixUrl = `${process.env.REACT_APP_IMGIX_URL}/shops${imgPath}${query}`;
    return imgixUrl;
  }
  return "invalid_img_url";
};

export default toImgixUrl;
